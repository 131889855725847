import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { handleError, handleSuccess } from '../utils';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';


function Users() {

    const [loggedInUser, setLoggedInUser] = useState('');
    const [users, setUsers] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        setLoggedInUser(localStorage.getItem('loggedInUser'))
    }, [])
    const handleLogout = (e) => {
        localStorage.removeItem('token');
        localStorage.removeItem('loggedInUser');
        handleSuccess('User Loggedout');
        setTimeout(() => {
            navigate('/login');
        }, 1000)
    }

    const fetchUsers = async () => {
        try {
            const url = "http://localhost:8080/user/users";
            const headers = {
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            }
            const response = await fetch(url, headers);
            const result = await response.json();
            console.log(result);
            setUsers(result.data.user);
        } catch (err) {
            handleError(err);
        }
    }
    useEffect(() => {
        fetchUsers()
    }, [])

    const [signupInfo, setSignupInfo] = useState({
        name: '',
        email: '',
        phone: '',
        password: ''
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        const copySignupInfo = { ...signupInfo };
        copySignupInfo[name] = value;
        setSignupInfo(copySignupInfo);
    }
    const handleSignup = async (e) => {
        e.preventDefault();
        const { name, email, phone, password } = signupInfo;
        if (!name || !email || !phone || !password) {
            return handleError('name, email, phone and password are required')
        }
        try {
            const url = `http://localhost:8080/auth/signup`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(signupInfo)
            });
            const result = await response.json();
            const { success, message, error } = result;
            if (success) {
                handleSuccess(message);
                // setTimeout(() => {
                //     navigate('/users')
                // }, 1000)
                setSignupInfo({name: '', email: '', phone: '', password: ''});
            } else if (error) {
                const details = error?.details[0].message;
                handleError(details);
            } else if (!success) {
                handleError(message);
            }
            console.log(result);
        } catch (err) {
            handleError(err);
        }
    }
    
  return (
    <div>
        <nav className="pc-sidebar">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <a href="index.html" className="b-brand text-primary">
                        <img src="https://ableproadmin.com/assets/images/logo-dark.svg"
                         className="img-fluid logo-lg" alt="logo" />
                    </a>
                </div>
                <div className="navbar-content">
                    <ul className="pc-navbar">
                        <li className="pc-item active">
                            <Link to="/dashboard"
                             className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon"><use xlinkHref="#custom-story"></use></svg>
                                </span>
                                <span className="pc-mtext">Dashboard</span>
                            </Link>
                        </li>
                        <li className="pc-item">
                            <Link to="/users"
                             className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon"><use xlinkHref="#custom-user"></use></svg>
                                </span>
                                <span className="pc-mtext">Users</span>
                            </Link>
                        </li>                                             
                    </ul>
                </div>
            </div>
        </nav>
        <header className="pc-header">
            <div className="header-wrapper">
                <div className="me-auto pc-mob-drp">
                    <ul className="list-unstyled">
                        <li className="pc-h-item pc-sidebar-collapse">
                            <a href="#" className="pc-head-link ms-0" id="sidebar-hide"><i className="ti ti-menu-2"></i></a>
                        </li>
                        <li className="pc-h-item pc-sidebar-popup">
                            <a href="#" className="pc-head-link ms-0" id="mobile-collapse"><i className="ti ti-menu-2"></i></a>
                        </li>
                    </ul>
                </div>
                <div className="ms-auto">
                    <ul className="list-unstyled">
                        <li className="dropdown pc-h-item header-user-profile">
                            <a className="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" data-bs-auto-close="outside" aria-expanded="false">
                                <img src="assets/images/user/avatar-2.jpg" alt={loggedInUser} className="user-avtar" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                                <a href="#!" className="dropdown-item"><i className="ti ti-user"></i> <span>My Account</span> </a><a href="#!" className="dropdown-item"><i className="ti ti-settings"></i> <span>Settings</span> </a>
                                <a href="#!" className="dropdown-item"><i className="ti ti-headset"></i> <span>Support</span> </a><a href="#!" className="dropdown-item"><i className="ti ti-lock"></i> <span>Lock Screen</span> </a>
                                <a href="#!" className="dropdown-item" onClick={handleLogout}><i className="ti ti-power"></i> <span>Logout</span></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <div className="pc-container">
            <div className="pc-content">
                <div className="row">
                    <div className="col-12">
                        <div className="card table-card">
                            <div className="card-header">
                                <div className="d-sm-flex align-items-center justify-content-between">
                                    <h5 className="mb-3 mb-sm-0">Users List</h5>
                                    <div>
                                        <a href="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_pc_layout" className="btn btn-primary">Add User</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-3">
                                <div className="table-responsive">
                                    <table className="table table-hover" id="pc-dt-simple">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { users && users?.map((item, index) => (    
                                            <tr key={index}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0"><img src="../assets/images/user/avatar-1.jpg" alt="user image" className="img-radius wid-40" /></div>
                                                        <div className="flex-grow-1 ms-3"><h6 className="mb-0">{item.name}</h6></div>
                                                    </div>
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{item.phone}</td>
                                                <td>{ moment(item.createdAt).format("DD-MM-YYYY") } </td>
                                                <td>
                                                    <a href="#" className="avtar avtar-xs btn-link-secondary"><i className="ti ti-eye f-20"></i> </a><a href="#" className="avtar avtar-xs btn-link-secondary"><i className="ti ti-edit f-20"></i> </a>
                                                    <a href="#" className="avtar avtar-xs btn-link-secondary"><i className="ti ti-trash f-20"></i></a>
                                                </td>
                                            </tr>
                                        )) }    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer className="pc-footer">
            <div className="footer-wrapper container-fluid">
                <div className="row">
                    <div className="col my-1">
                        <p className="m-0">Able Pro &#9829; crafted by Team <a href="https://themeforest.net/user/phoenixcoded" target="_blank">Phoenixcoded</a></p>
                    </div>
                    <div className="col-auto my-1">
                        <ul className="list-inline footer-link mb-0">
                            <li className="list-inline-item"><a href="https://ableproadmin.com/index.html">Home</a></li>
                            <li className="list-inline-item"><a href="https://phoenixcoded.gitbook.io/able-pro/" target="_blank">Documentation</a></li>
                            <li className="list-inline-item"><a href="https://phoenixcoded.authordesk.app/" target="_blank">Support</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

        <div className="offcanvas border-0 pct-offcanvas offcanvas-end" tabindex="-1" id="offcanvas_pc_layout">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Add User</h5>
                <button type="button" className="btn btn-icon btn-link-danger ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"><i className="ti ti-x"></i></button>
            </div>
            <div className="pct-body customizer-body">
                <div className="offcanvas-body py-0">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <div className="pc-dark">
                                <form onSubmit={handleSignup}>
                                    <div class="modal-body">
                                        <div class="mb-3"><label class="form-label">Full Name</label> <input type="text" name="name" value={signupInfo.name} onChange={handleChange} class="form-control" placeholder="Full Name" /></div>
                                        <div class="mb-3"><label class="form-label">Email Address</label> <input type="email" name="email" value={signupInfo.email} onChange={handleChange} class="form-control" placeholder="Email Address" /></div>
                                        <div class="mb-3"><label class="form-label">Mobile Number</label> <input type="text" name="phone" value={signupInfo.naphoneme} onChange={handleChange} class="form-control" placeholder="Mobile Number" /></div>
                                        <div class="mb-3"><label class="form-label">Password</label> <input type="password" name="password" value={signupInfo.password} onChange={handleChange} class="form-control" placeholder="Enter Password" /></div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light-danger" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                                        <button type="submit" class="btn btn-light-primary">Save changes</button>
                                    </div>
                                </form>
                                <ToastContainer />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>



    </div>
  )
}

export default Users
