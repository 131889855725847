import React from 'react'
import { Link } from 'react-router-dom';

function Reset() {
  return (
    <div>
        <div className="auth-main">
            <div className="auth-wrapper v2">
                <div className="auth-sidecontent">
                	<img src="assets/images/authentication/img-auth-sideimg.jpg" alt="images" className="img-fluid img-auth-side" />
                </div>
                <div className="auth-form">
                    <div className="card my-5">
                        <div className="card-body">
                            <Link to="/reset">
                            	<img src="https://ableproadmin.com/assets/images/logo-dark.svg" className="mb-4 img-fluid" alt="img" />
                            </Link>
                            <div className="mb-4">
                                <h3 className="mb-2">
                                	<b>Reset Password</b>
                                </h3>
                                <p className="text-muted">Please choose your new password</p>
                            </div>
                            <div className="mb-3">
                            	<label className="form-label">Password</label>
                            	<input type="password" className="form-control" id="floatingInput" placeholder="Password" />
                            </div>
                            <div className="mb-3">
                            	<label className="form-label">Confirm Password</label>
                            	<input type="password" className="form-control" id="floatingInput1" placeholder="Confirm Password" />
                            </div>
                            <div className="d-grid mt-4">
                            	<button type="button" className="btn btn-primary">Reset Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Reset
