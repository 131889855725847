import React from 'react';
import {BrowserRouter, Navigate, Routes, Route} from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Users from './Pages/Users';
import Forgot from './Pages/Forgot';
import Register from './Pages/Register';
import Reset from './Pages/Reset';
import Verification from './Pages/Verification';
import { useState } from 'react';
import RefrshHandler from './RefrshHandler';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />
  }
  return (
    <div className="App">      
      <BrowserRouter>
      <RefrshHandler setIsAuthenticated={setIsAuthenticated} />
      <Routes>
            {/* pages */}
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgot' element={<Forgot />} />
            <Route path='/register' element={<Register />} />
            <Route path='/reset' element={<Reset />} />
            <Route path='/verification' element={<Verification />} />
            <Route path='/dashboard' element={<PrivateRoute element={<Dashboard />} />} />
            <Route path='/users' element={<PrivateRoute element={<Users />} />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
