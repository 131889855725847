import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { handleError, handleSuccess } from '../utils';

function Login() {
    const [loginInfo, setLoginInfo] = useState({email: '',password: ''})
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        const copyLoginInfo = { ...loginInfo };
        copyLoginInfo[name] = value;
        setLoginInfo(copyLoginInfo);
    }
    const handleLogin = async (e) => {
        e.preventDefault();
        const { email, password } = loginInfo;
        if (!email || !password) {
            return handleError('Email and Password required')
        }
        try {
            const url = `http://localhost:8080/auth/login`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginInfo)
            });
            const result = await response.json();
            const { success, message, jwtToken, name, error } = result;
            if (success) {
                handleSuccess(message);
                localStorage.setItem('token', jwtToken);
                localStorage.setItem('loggedInUser', name);
                setTimeout(() => {
                    navigate('/dashboard')
                }, 1000)
            } else if (error) {
                const details = error?.details[0].message;
                handleError(details);
            } else if (!success) {
                handleError(message);
            }
            console.log(result);
        } catch (err) {
            handleError(err);
        }
    }
  return (
    <div>
        <div className="auth-main">
            <div className="auth-wrapper v2">
                <div className="auth-sidecontent">
                	<img src="assets/images/authentication/img-auth-sideimg.jpg"
                	 alt="images" className="img-fluid img-auth-side" />
                </div>
                <div className="auth-form">
                    <div className="card my-5">
                        <div className="card-body">
                            <Link to="/">
                            	<img src="https://ableproadmin.com/assets/images/logo-dark.svg"
                            	 className="mb-4 img-fluid" alt="img" />
                            </Link>
                            <div className="mb-4">
                                <h3 className="mb-2">
                                	<b>Account Login</b>
                                </h3>
                                <p className="text-muted">Login with your registered email</p>
                            </div>
                            <form onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <input type="email" name="email" onChange={handleChange} value={loginInfo.email} className="form-control" placeholder="Enter Email" />
                                </div>
                                <div className="mb-3">
                                    <input type="password" name="password" onChange={handleChange} value={loginInfo.password} className="form-control" placeholder="Enter Password" />
                                </div>
                                <div className="d-flex mt-1 justify-content-between align-items-center">
                                    <div className="form-check">
                                        <input className="form-check-input input-primary" type="checkbox" id="customCheckc1" defaultChecked />
                                        <label className="form-check-label text-muted" htmlFor="customCheckc1">Remember me?</label>
                                    </div>
                                    <h6 className="text-secondary f-w-400 mb-0">
                                        <Link to="/forgot">Forgot Password?</Link>
                                    </h6>
                                </div>
                                <div className="d-grid mt-4">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                            </form>
                            <ToastContainer />
                            <div className="d-flex justify-content-between align-items-end mt-4">
                                <h6 className="f-w-500 mb-0">Don't have an Account?</h6>
                                <Link to="/register" className="link-primary">Create Account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login
