import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { handleError, handleSuccess } from '../utils';

function Register() {
    const [signupInfo, setSignupInfo] = useState({
        name: '',
        email: '',
        phone: '',
        password: ''
    })

    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        const copySignupInfo = { ...signupInfo };
        copySignupInfo[name] = value;
        setSignupInfo(copySignupInfo);
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        const { name, email, phone, password } = signupInfo;
        if (!name || !email || !phone || !password) {
            return handleError('name, email, phone and password are required')
        }
        try {
            const url = `http://localhost:8080/auth/signup`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(signupInfo)
            });
            const result = await response.json();
            const { success, message, error } = result;
            if (success) {
                handleSuccess(message);
                // setTimeout(() => {
                //     navigate('/login')
                // }, 1000)
                setSignupInfo({name: '', email: '', phone: '', password: ''});
            } else if (error) {
                const details = error?.details[0].message;
                handleError(details);
            } else if (!success) {
                handleError(message);
            }
            console.log(result);
        } catch (err) {
            handleError(err);
        }
    }


  return (
    <div>
        <div class="auth-main">
            <div class="auth-wrapper v2">
                <div class="auth-sidecontent">
                	<img src="assets/images/authentication/img-auth-sideimg.jpg" alt="images" class="img-fluid img-auth-side" />
                </div>
                <div class="auth-form">
                    <div class="card my-5">
                        <div class="card-body">
                            <div class="text-center">
                                <Link to="/register">
                                	<img src="https://ableproadmin.com/assets/images/logo-dark.svg" alt="img" />
                                </Link>
                            </div>
                            <h4 class="text-center f-w-500 mb-5 my-5">Sign up with your work email.</h4>
                            <form onSubmit={handleSignup}>
                                <div class="mb-3">
                                    <input type="text" name="name" value={signupInfo.name} onChange={handleChange} class="form-control" placeholder="Full Name" />
                                </div>
                                <div class="mb-3">
                                    <input type="email" name="email" value={signupInfo.email} onChange={handleChange} class="form-control" placeholder="Email Address" />
                                </div>
                                <div class="mb-3">
                                    <input type="text" name="phone" value={signupInfo.naphoneme} onChange={handleChange} class="form-control" placeholder="Mobile Number" />
                                </div>                            
                                <div class="mb-3">
                                    <input type="password" name="password" value={signupInfo.password} onChange={handleChange} class="form-control" placeholder="Password" />
                                </div>
                                <div class="d-grid mt-4">
                                    <button type="submit" class="btn btn-primary">Sign up</button>
                                </div>
                            </form>
                            <ToastContainer />
                            <div class="d-flex justify-content-between align-items-end mt-4">
                                <h6 class="f-w-500 mb-0">Already have an Account?</h6>
                                <Link to="/" class="link-primary">Login here</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register
