import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { handleError, handleSuccess } from '../utils';
import { ToastContainer } from 'react-toastify';


function Dashboard() {

    const [loggedInUser, setLoggedInUser] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        setLoggedInUser(localStorage.getItem('loggedInUser'))
    }, [])
    const handleLogout = (e) => {
        localStorage.removeItem('token');
        localStorage.removeItem('loggedInUser');
        handleSuccess('User Loggedout');
        setTimeout(() => {
            navigate('/login');
        }, 1000)
    }
    
  return (
    <div>
        <nav className="pc-sidebar">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <a href="index.html" className="b-brand text-primary">
                        <img src="https://ableproadmin.com/assets/images/logo-dark.svg"
                         className="img-fluid logo-lg" alt="logo" />
                    </a>
                </div>
                <div className="navbar-content">
                    <ul className="pc-navbar">
                        <li className="pc-item active">
                            <Link to="/dashboard"
                             className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon"><use xlinkHref="#custom-story"></use></svg>
                                </span>
                                <span className="pc-mtext">Dashboard</span>
                            </Link>
                        </li>
                        <li className="pc-item">
                            <Link to="/users"
                             className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon"><use xlinkHref="#custom-user"></use></svg>
                                </span>
                                <span className="pc-mtext">Users</span>
                            </Link>
                        </li>                                             
                    </ul>
                </div>
            </div>
        </nav>
        <header className="pc-header">
            <div className="header-wrapper">
                <div className="me-auto pc-mob-drp">
                    <ul className="list-unstyled">
                        <li className="pc-h-item pc-sidebar-collapse">
                            <a href="#" className="pc-head-link ms-0" id="sidebar-hide"><i className="ti ti-menu-2"></i></a>
                        </li>
                        <li className="pc-h-item pc-sidebar-popup">
                            <a href="#" className="pc-head-link ms-0" id="mobile-collapse"><i className="ti ti-menu-2"></i></a>
                        </li>
                    </ul>
                </div>
                <div className="ms-auto">
                    <ul className="list-unstyled">
                        <li className="dropdown pc-h-item header-user-profile">
                            <a className="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" data-bs-auto-close="outside" aria-expanded="false">
                                <img src="assets/images/user/avatar-2.jpg" alt={loggedInUser} className="user-avtar" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                                <a href="#!" className="dropdown-item"><i className="ti ti-user"></i> <span>My Account</span> </a><a href="#!" className="dropdown-item"><i className="ti ti-settings"></i> <span>Settings</span> </a>
                                <a href="#!" className="dropdown-item"><i className="ti ti-headset"></i> <span>Support</span> </a><a href="#!" className="dropdown-item"><i className="ti ti-lock"></i> <span>Lock Screen</span> </a>
                                <a href="#!" className="dropdown-item" onClick={handleLogout}><i className="ti ti-power"></i> <span>Logout</span></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <div className="pc-container">
            <div className="pc-content">
                <div className="row">
                    <div className="col-md-6 col-xxl-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <div className="avtar avtar-s bg-light-primary">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" d="M13 9H7" stroke="#4680FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    d="M22.0002 10.9702V13.0302C22.0002 13.5802 21.5602 14.0302 21.0002 14.0502H19.0402C17.9602 14.0502 16.9702 13.2602 16.8802 12.1802C16.8202 11.5502 17.0602 10.9602 17.4802 10.5502C17.8502 10.1702 18.3602 9.9502 18.9202 9.9502H21.0002C21.5602 9.9702 22.0002 10.4202 22.0002 10.9702Z"
                                                    stroke="#4680FF"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z"
                                                    stroke="#4680FF"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3"><h6 className="mb-0">All Earnings</h6></div>
                                    <div className="flex-shrink-0 ms-3">
                                        <div className="dropdown">
                                            <a className="avtar avtar-s btn-link-secondary dropdown-toggle arrow-none" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti ti-dots-vertical f-18"></i></a>
                                            <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#">Today</a> <a className="dropdown-item" href="#">Weekly</a> <a className="dropdown-item" href="#">Monthly</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-body p-3 mt-3 rounded">
                                    <div className="mt-3 row align-items-center">
                                        <div className="col-7"><div id="all-earnings-graph"></div></div>
                                        <div className="col-5">
                                            <h5 className="mb-1">$3,020</h5>
                                            <p className="text-primary mb-0"><i className="ti ti-arrow-up-right"></i> 30.6%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xxl-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <div className="avtar avtar-s bg-light-warning">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                                                    stroke="#E58A00"
                                                    strokeWidth="1.5"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path opacity="0.6" d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5" stroke="#E58A00" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.6" d="M8 13H12" stroke="#E58A00" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.6" d="M8 17H16" stroke="#E58A00" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3"><h6 className="mb-0">Page Views</h6></div>
                                    <div className="flex-shrink-0 ms-3">
                                        <div className="dropdown">
                                            <a className="avtar avtar-s btn-link-secondary dropdown-toggle arrow-none" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti ti-dots-vertical f-18"></i></a>
                                            <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#">Today</a> <a className="dropdown-item" href="#">Weekly</a> <a className="dropdown-item" href="#">Monthly</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-body p-3 mt-3 rounded">
                                    <div className="mt-3 row align-items-center">
                                        <div className="col-7"><div id="page-views-graph"></div></div>
                                        <div className="col-5">
                                            <h5 className="mb-1">290K+</h5>
                                            <p className="text-warning mb-0"><i className="ti ti-arrow-up-right"></i> 30.6%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xxl-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <div className="avtar avtar-s bg-light-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 2V5" stroke="#2ca87f" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16 2V5" stroke="#2ca87f" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M3.5 9.08984H20.5" stroke="#2ca87f" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                                                    stroke="#2ca87f"
                                                    strokeWidth="1.5"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path opacity="0.4" d="M15.6947 13.7002H15.7037" stroke="#2ca87f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M15.6947 16.7002H15.7037" stroke="#2ca87f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M11.9955 13.7002H12.0045" stroke="#2ca87f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M11.9955 16.7002H12.0045" stroke="#2ca87f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M8.29431 13.7002H8.30329" stroke="#2ca87f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M8.29395 16.7002H8.30293" stroke="#2ca87f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3"><h6 className="mb-0">Total Task</h6></div>
                                    <div className="flex-shrink-0 ms-3">
                                        <div className="dropdown">
                                            <a className="avtar avtar-s btn-link-secondary dropdown-toggle arrow-none" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti ti-dots-vertical f-18"></i></a>
                                            <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#">Today</a> <a className="dropdown-item" href="#">Weekly</a> <a className="dropdown-item" href="#">Monthly</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-body p-3 mt-3 rounded">
                                    <div className="mt-3 row align-items-center">
                                        <div className="col-7"><div id="total-task-graph"></div></div>
                                        <div className="col-5">
                                            <h5 className="mb-1">839</h5>
                                            <p className="text-success mb-0"><i className="ti ti-arrow-up-right"></i> New</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xxl-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <div className="avtar avtar-s bg-light-danger">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                    stroke="#DC2626"
                                                    strokeWidth="1.5"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path opacity="0.4" d="M8.4707 10.7402L12.0007 14.2602L15.5307 10.7402" stroke="#DC2626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3"><h6 className="mb-0">Download</h6></div>
                                    <div className="flex-shrink-0 ms-3">
                                        <div className="dropdown">
                                            <a className="avtar avtar-s btn-link-secondary dropdown-toggle arrow-none" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti ti-dots-vertical f-18"></i></a>
                                            <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#">Today</a> <a className="dropdown-item" href="#">Weekly</a> <a className="dropdown-item" href="#">Monthly</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-body p-3 mt-3 rounded">
                                    <div className="mt-3 row align-items-center">
                                        <div className="col-7"><div id="download-graph"></div></div>
                                        <div className="col-5">
                                            <h5 className="mb-1">2,067</h5>
                                            <p className="text-danger mb-0"><i className="ti ti-arrow-up-right"></i> 30.6%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-grow-1"><h5 className="mb-0">Repeat customer rate</h5></div>
                                    <div className="flex-shrink-0 ms-3">
                                        <div className="dropdown">
                                            <a className="avtar avtar-s btn-link-secondary dropdown-toggle arrow-none" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti ti-dots f-18"></i></a>
                                            <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#">Today</a> <a className="dropdown-item" href="#">Weekly</a> <a className="dropdown-item" href="#">Monthly</a></div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="text-end my-2">5.44% <span className="badge bg-success">+2.6%</span></h5>
                                <div id="customer-rate-graph"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-header"><h5 className="mb-0">Project - Able Pro</h5></div>
                            <div className="card-body">
                                <div className="mb-4">
                                    <p className="mb-2">Release v1.2.0<span className="float-end">70%</span></p>
                                    <div className="progress progress-primary" style={{height: '8px'}}><div className="progress-bar" style={{width: '70%'}}></div></div>
                                </div>
                                <div className="d-grid gap-2">
                                    <a href="#" className="btn btn-link-secondary">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <span className="p-1 d-block bg-warning rounded-circle"><span className="visually-hidden">New alerts</span></span>
                                            </div>
                                            <div className="flex-grow-1 mx-2">
                                                <p className="mb-0 d-grid text-start"><span className="text-truncate w-100">Horizontal Layout</span></p>
                                            </div>
                                            <div className="badge bg-light-secondary f-12"><i className="ti ti-paperclip text-sm"></i> 2</div>
                                        </div>
                                    </a>
                                    <a href="#" className="btn btn-link-secondary">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <span className="p-1 d-block bg-warning rounded-circle"><span className="visually-hidden">New alerts</span></span>
                                            </div>
                                            <div className="flex-grow-1 mx-2">
                                                <p className="mb-0 d-grid text-start"><span className="text-truncate w-100">Invoice Generator</span></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#" className="btn btn-link-secondary">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <span className="p-1 d-block bg-warning rounded-circle"><span className="visually-hidden">New alerts</span></span>
                                            </div>
                                            <div className="flex-grow-1 mx-2">
                                                <p className="mb-0 d-grid text-start"><span className="text-truncate w-100">Package Upgrades</span></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#" className="btn btn-link-secondary">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <span className="p-1 d-block bg-success rounded-circle"><span className="visually-hidden">New alerts</span></span>
                                            </div>
                                            <div className="flex-grow-1 mx-2">
                                                <p className="mb-0 d-grid text-start"><span className="text-truncate w-100">Figma Auto Layout</span></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="d-grid mt-3">
                                    <button className="btn btn-primary d-flex align-items-center justify-content-center gap-2"><i className="ti ti-plus"></i> Add task</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer className="pc-footer">
            <div className="footer-wrapper container-fluid">
                <div className="row">
                    <div className="col my-1">
                        <p className="m-0">Able Pro &#9829; crafted by Team <a href="https://themeforest.net/user/phoenixcoded" target="_blank">Phoenixcoded</a></p>
                    </div>
                    <div className="col-auto my-1">
                        <ul className="list-inline footer-link mb-0">
                            <li className="list-inline-item"><a href="https://ableproadmin.com/index.html">Home</a></li>
                            <li className="list-inline-item"><a href="https://phoenixcoded.gitbook.io/able-pro/" target="_blank">Documentation</a></li>
                            <li className="list-inline-item"><a href="https://phoenixcoded.authordesk.app/" target="_blank">Support</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Dashboard
